import produce from "immer";
import * as Actions from "../actions";

const initState = {
  loading: false,
  configurationInfo: {},
  type: "",
};

const Lights = (state = initState, action) => {
  const { type, payload } = action;
  switch (type) {
    case Actions.GET_BM_CONFIGURATION_INFO.REQUEST:
      return produce(state, (draft) => {
        draft.loading = true;
        draft.type = type;
      });
    case Actions.GET_BM_CONFIGURATION_INFO.SUCCESS:
      return produce(state, (draft) => {
        draft.loading = false;
        draft.configurationInfo = payload;
        draft.type = type;
      });
    case Actions.GET_BM_CONFIGURATION_INFO.FAILURE:
      return produce(state, (draft) => {
        draft.loading = false;
        draft.type = type;
      });
    default:
      return state;
  }
};

export default Lights;
