import TextConstants from "./TextConstants";

export const MAX_NUM_SETUP_USER_EACH_FACILITY = 5;
export const MAX_NUM_REGISTERED_FACILITIES = 1000; // EACH COMPANY
export const MAX_NUM_REGISTERED_FLOORS = 100; // EACH FACILITY
export const MAX_NUM_USERS_INVITED = 1000; // EACH COMPANY

export const MAX_NUM_USERS_INVITE_ONE_TIME = 50; // Invite user join to a facility

export const LOCAL_STORAGE_KEYS = {
  NEW_DEPLOYMENT_SETTINGS: "NEW_DEPLOYMENT_SETTINGS",
};

export const ROLES = {
  SA: "SYSTEM_ADMIN",
  FA: "FACILITY_ADMIN",
  SU: "SETUP_USER",

  CA: "COMPANY_ADMIN",
  DA: "DEPARTMENT_ADMIN",
  GU: "GENERAL_USER",
};

export const FACILITY_ADMIN_ROLES = {
  GUEST: "GUEST",
  OWNER: "OWNER",
};

export const FACILITY_USERS_STATUS = {
  ACCEPTED: "ACCEPTED",
  PENDING: "PENDING",
};

export const NEW_FLOOR_MAP_SETTING_MODE = {
  EASY: "EASY",
  MANUAL: "MANUAL",
};

export const FLOOR_SETTINGS_FILE_TYPE = {
  MAP_IMAGE: "MAP_IMAGE",
  BM_CONFIG: "BM_CONFIG",
};

export const MAP_LIGHT_EDIT_MODE = {
  ADD: "ADD",
  DELETE: "DELETE",
};

export const MANAGED_ROLES = {
  [ROLES.SA]: [ROLES.SA, ROLES.GU],
  // [ROLES.SA]: [ROLES.SA, ROLES.CA, ROLES.DA, ROLES.GU],
  // [ROLES.CA]: [ROLES.CA, ROLES.DA, ROLES.GU],
  // [ROLES.DA]: [ROLES.DA, ROLES.GU],
  // [ROLES.GU]: [],
};

export const FEEDBACK_TYPE = {
  FEEDBACK: "FEEDBACK",
  BUGS_REPORT: "BUGS_REPORT",
};

// accept alphabet character, number, as least a Upper and Lower
export const PASSWORD_REGEX =
  /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[ ^$*.\[\]{}()?\"!@#%&/\\,><':;|_~`=+\-])[a-zA-Z\d ^$*.\[\]{}()?\"!@#%&/\\,><':;|_~`=+\-]{8,99}$/;
export const EMAIL_REGEX = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
export const ASCII_REGEX = /^[\x00-\x7F]+$/;

export const JWT_REGEX = /^[\w-]*\.[\w-]*\.[\w-]*$/;

export const API_ENDPOINTS = {
  BE_VERSION: "version",

  LOGIN: "auth/login",
  LOGOUT: "auth/logout",
  SIGNUP: "auth/signup",
  PASSWORD_RESET_REQUEST: "auth/password-reset-request",
  RESET_PASSWORD: "auth/reset-password",
  REFRESH_TOKEN: "auth/refresh",
  CONFIRM_EMAIL_ADDRESS: "auth/confirm-email-address",
  COMPANIES: "companies",
  OFFICES: "offices",
  USERS: "users",
  UPDATE_USER_INFO: "users/info",
  FACILITIES: "facilities",
  FACILITY: "facilities/{:facilityId}",
  FACILITIES_USERS: "facilities/{:facilityId}/users",
  FACILITIES_USER: "facilities/{:facilityId}/users/{:email}",
  ACCEPT_FACILITY_INVITATION: "accept-facility-invitation",
  GET_FACILITY_INVITATIONS: "facilities/invitations",
  ACCEPT_FACILITY_INVITATION_VIA_DASHBOARD: "facilities/{:facilityId}/invitations/accept",
  REJECT_FACILITY_INVITATION_VIA_DASHBOARD: "facilities/{:facilityId}/invitations/reject",

  FLOORS: "facilities/{:facilityId}/floors",
  FLOOR_OPERATION_LOGS: "facilities/{:facilityId}/floors/{:floorId}/operation-logs",
  FACILITY_FLOOR: "facilities/{:facilityId}/floors/{:floorId}",
  FACILITY_FLOOR_V2: "v2/facilities/{:facilityId}/floors/{:floorId}",
  GEN_FLOORS_SETTINGS_FILE_PRESIGNED_URL_POST:
    "floors/gen-floor-settings-file-presigned-url-post",
  GET_FLOORS_SETTINGS_FILE_PRESIGNED_URL:
    "floors/get-floor-settings-file-presigned-url",
  LIGHT_TYPES: "light-types ",

  BMS_CONFIGURATION_INFORMATION: "bms/{bmId}/information",

  FEEDBACK: "feedback",
  INVITE_USERS: "invite-users",
};

export const UNAUTH_ENDPOINTS = [
  API_ENDPOINTS.LOGIN,
  API_ENDPOINTS.LOGOUT,
  API_ENDPOINTS.REFRESH_TOKEN,
];

export const PROPERTY_TYPES = {
  SKOOL: {
    KEY: "SKOOL",
    LABEL: TextConstants.PropertyType.School,
  },
  OFFICE: {
    KEY: "OFFICE",
    LABEL: TextConstants.PropertyType.SmallOffice,
  },
  MEDICAL: {
    KEY: "MEDICAL",
    LABEL: TextConstants.PropertyType.MedicalOffice,
  },
  WAREHOUSE: {
    KEY: "WAREHOUSE",
    LABEL: TextConstants.PropertyType.SmallFactoryWarehouse,
  },
};

export const FLOOR_SETTINGS_MODE_LABEL = {
  EASY: TextConstants.NewFloorMapSettings.EasyRegistration,
  MANUAL: TextConstants.NewFloorMapSettings.MakeSureToRegister,
};

export const NEW_DEPLOYMENT_STEPS_BY_MODE = {
  EASY: [
    TextConstants.NewDeploymentSteps.Initial,
    TextConstants.NewDeploymentSteps.LightingSelection,
    TextConstants.NewDeploymentSteps.EZSettings,
    TextConstants.NewDeploymentSteps.Confirm,
  ],
  MANUAL: [
    TextConstants.NewDeploymentSteps.Initial,
    TextConstants.NewDeploymentSteps.LightingSelection,
    TextConstants.NewDeploymentSteps.LightSettingsConfirm,
    TextConstants.NewDeploymentSteps.SettingUpTheLight,
    TextConstants.NewDeploymentSteps.Confirm,
  ],
};

export const SENSOR_OPERATION_METHOD_LABEL = {
  SWITCH: TextConstants.OperationMethod.Switch,
  SCHEDULE: TextConstants.OperationMethod.Schedules,
  SENSOR: TextConstants.OperationMethod.Sensors,
  SCENE: TextConstants.OperationMethod.Scenes,
  SMARTPHONE: TextConstants.OperationMethod.SmartphoneControl,
};

export const COLOR_SLIDER_GRADIENT = [
  "#f9bd70",
  "#f7e690",
  "#fefbcb",
  "#faffff",
  "#c3dbe7",
  "#a2c2fd",
  "#b9bedf",
];

export const BRIGHTNESS_VALUES = {
  min: 0,
  max: 100,
};

export const COLOR_VALUES = {
  min: 0,
  max: 20,
};

export const DAY_IN_WEEK_LABELS = ["月", "火", "水", "木", "金", "土", "日"]
